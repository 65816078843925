<template>
  <v-card flat tile>
    <v-toolbar color="white" flat class="px-3">
      <v-toolbar-title class="text-h5 font-weight-light text-capitalize">
        {{ $t('users.holidayMode') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="px-6">
      <v-alert dense type="info">
        {{ $t('users.holidayModeInfo') }}
      </v-alert>
      <v-switch
        class="mt-0 mb-4"
        v-model="holidayMode"
        :label="holidayModeText"
        color="app-blue"
        hide-details
        @change="change"
      ></v-switch>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { CHANGE_HOLIDAY_MODE } from '../../store/users/types';

export default {
  name: 'holiday-mode',
  computed: {
    ...mapGetters(['currentUser']),
    holidayModeText() {
      return this.holidayMode
        ? this.$i18n.t('common.enabled')
        : this.$i18n.t('common.disabled');
    }
  },
  data() {
    return {
      loading: false,
      holidayMode: false
    };
  },
  created() {
    this.holidayMode = this.currentUser.holidayMode;
  },
  methods: {
    async change() {
      await this.$store.dispatch(CHANGE_HOLIDAY_MODE, this.holidayMode);

      if (this.holidayMode) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('users.successfullyEnabledHolidayMode')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('users.successfullyDisabledHolidayMode')
        });
      }
    }
  }
};
</script>
