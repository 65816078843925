<template>
  <v-card flat tile>
    <v-toolbar color="white" flat class="px-3">
      <v-toolbar-title class="text-h5 font-weight-light text-capitalize">
        {{ $t('users.changePassword') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="px-6">
      <v-form ref="changePasswordForm">
        <v-text-field
          solo
          v-model="passwordData.oldPassword"
          :rules="[rules.required]"
          type="password"
          :label="$t('common.oldPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          solo
          v-model="passwordData.newPassword"
          :rules="[rules.required, rules.passwordMinLength]"
          type="password"
          :label="$t('common.newPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <v-text-field
          solo
          v-model="passwordData.confirmNewPassword"
          :rules="[
            rules.required,
            rules.equalWithPassword(passwordData.newPassword)
          ]"
          type="password"
          :label="$t('common.confirmNewPassword')"
          prepend-inner-icon="mdi-lock"
        ></v-text-field>
        <div class="text-right">
          <v-btn dark color="app-blue" @click="save" :loading="loading">
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { CHANGE_PASSWORD } from '../../store/users/types';
import validators from '../../common/validators';

const PASSWORD_MIN_LENGTH = 6;

export default {
  name: 'change-password-form',
  data() {
    return {
      loading: false,
      passwordData: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        equalWithPassword: prop =>
          validators.equal(prop, this.$i18n.t('common.passwordMismatch')),
        passwordMinLength: validators.min(
          PASSWORD_MIN_LENGTH,
          this.$i18n.t('common.minLength', {
            value: PASSWORD_MIN_LENGTH
          })
        )
      }
    };
  },
  methods: {
    async save() {
      if (!this.$refs.changePasswordForm.validate()) {
        return;
      }

      try {
        this.loading = true;

        await this.$store.dispatch(CHANGE_PASSWORD, this.passwordData);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('common.successfullySavedChanges')
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
