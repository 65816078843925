<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular pr-3">
        {{ $t('users.changeProfileImage') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="pa-6 pb-0">
      <v-row justify="center" class="mb-3">
        <v-btn @click="$emit('close')" to="/store" dark color="app-blue">
          {{ $t('avatars.buyMoreAvatars') }}
        </v-btn>
      </v-row>
      <v-row v-if="avatars.length > 0">
        <v-col
          md="3"
          sm="4"
          cols="12"
          v-for="avatar of avatars"
          :key="avatar.id"
          class="my-1"
        >
          <edit-avatar-item
            :avatar="avatar"
            @selectProfileAvatar="selectProfileAvatar"
          ></edit-avatar-item>
        </v-col>
      </v-row>
      <div v-else class="text-center py-4">
        <h1 class="heading app-dark-gray--text font-weight-light">
          {{ $t('avatars.noAvatarsFound') }}
        </h1>
      </div>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="apply()">
        {{ $t('common.select') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import EditAvatarItem from './EditAvatarItem';
import httpAvatarsService from '../../services/avatars/avatars.http.service';

export default {
  name: 'edit-profile-image-modal',
  components: {
    EditAvatarItem
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      avatars: [],
      selectedAvatar: null
    };
  },
  created() {
    this.loadAvatars();
  },
  methods: {
    async loadAvatars() {
      const { result } = await httpAvatarsService.index();

      this.mapAvatars(result);
    },
    async mapAvatars(avatars) {
      let currentAvatarIndex = -1;
      const { avatar } = this.currentUser;

      if (avatar && avatar.id) {
        currentAvatarIndex = avatars.findIndex(a => a.id === avatar.id);
      }

      if (currentAvatarIndex > -1) {
        const currentAvatar = avatars[currentAvatarIndex];

        avatars.splice(currentAvatarIndex, 1);
        // currentAvatar.isCurrent = true; // Use this when add S3
        currentAvatar.isCurrent = !this.currentUser.generatedAvatarUrl; // TODO: Remove this when use S3
        avatars.unshift(currentAvatar);
      }

      for (const a of avatars) {
        if (this.currentUser.avatar && this.currentUser.avatar.id === a.id) {
          a.selected = true;
          this.selectedAvatar = a;
        } else {
          a.selected = false;
        }
      }

      this.avatars = avatars;
    },
    resetAvatars() {
      for (const avatar of this.avatars) {
        avatar.selected = false;
      }
    },
    selectProfileAvatar(avatar) {
      this.resetAvatars();
      this.selectedAvatar = avatar;
      avatar.selected = true;
    },
    apply() {
      if (this.selectedAvatar) {
        this.$emit('close', this.selectedAvatar);
      }
    }
  }
};
</script>
