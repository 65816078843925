<template>
  <v-container fluid grid-list-md class="pa-4">
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center my-3">
          <support-us></support-us>
        </div>
        <div class="text-center mb-2">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="border-top-blue mb-3" tile>
          <v-card-title
            class="text-h5 app-dark-gray--text font-weight-light py-3"
          >
            <v-icon left>mdi-cog</v-icon>
            <span>{{ $t('common.settings') }}</span>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col md="6" sm="12" cols="12" class="pt-0">
        <edit-user-profile-form></edit-user-profile-form>
      </v-col>
      <v-col md="6" sm="12" cols="12" class="pt-0">
        <change-password-form></change-password-form>
      </v-col>
      <v-col md="6" sm="12" cols="12" class="pt-0">
        <holiday-mode></holiday-mode>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import EditUserProfileForm from '../components/users/EditUserProfileForm';
import ChangePasswordForm from '../components/users/ChangePasswordForm';
import HolidayMode from '../components/users/HolidayMode.vue';
import dailyCronMixin from '../mixins/daily.cron.mixin';

export default {
  name: 'settings',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    EditUserProfileForm,
    ChangePasswordForm,
    HolidayMode
  },
  mixins: [dailyCronMixin]
};
</script>
