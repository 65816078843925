<template>
  <v-card
    class="text-center avatar-item"
    v-bind:class="{ pointer: !avatar.isCurrent }"
    @click="selectProfileAvatar()"
    tile
    :ripple="false"
  >
    <v-avatar size="125" class="mt-4">
      <v-img contain :src="avatarImg"></v-img>
    </v-avatar>
    <v-card-actions class="mt-2 justify-center">
      <v-btn v-if="avatar.isCurrent" disabled text dark block color="app-blue">
        <span class="grey--text text--lighten-1">
          {{ $t('common.current') }}
        </span>
      </v-btn>
      <v-btn
        v-else
        text
        dark
        block
        color="app-blue"
        @click="selectProfileAvatar()"
      >
        <v-icon
          v-if="avatar.selected"
          color="app-green"
          class="fas fa-check-circle"
        ></v-icon>
        <v-icon v-else color="app-green" class="far fa-circle"></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'edit-avatar-item',
  props: {
    avatar: {
      type: Object,
      require: true
    }
  },
  computed: {
    avatarImg() {
      return require(`@/assets/images/avatars/${this.avatar.imagePath}`);
    }
  },
  methods: {
    selectProfileAvatar() {
      if (this.avatar.isCurrent || this.avatar.selected) return;

      this.$emit('selectProfileAvatar', this.avatar);
    }
  }
};
</script>
