<template>
  <v-card flat tile>
    <v-toolbar color="white" flat class="px-3">
      <v-toolbar-title class="text-h5 font-weight-light text-capitalize">
        {{ $t('users.editProfile') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="px-6">
      <v-form ref="editProfileForm">
        <v-text-field
          solo
          v-model="user.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
          prepend-inner-icon="mdi-account"
        ></v-text-field>
        <div class="text-center mb-5">
          <user-avatar
            :user="user"
            :size="120"
            textSizeClass="text-h4"
            class="mb-5"
          ></user-avatar>
          <v-btn dark color="app-blue" @click="changeProfileImage()">
            {{ $t('users.changeProfileImage') }}
          </v-btn>
          <v-btn
            v-if="isAvatarGenerationEnabled"
            class="ml-2"
            dark
            color="app-blue"
            @click="openGenerateProfileImage()"
          >
            {{ $t('users.generateProfileImage') }}
          </v-btn>
        </div>
        <v-select
          solo
          :items="languages"
          v-model="user.lang"
          :label="$t('common.language')"
          item-value="value"
          item-text="key"
        ></v-select>

        <div class="text-right">
          <v-btn dark color="app-blue" @click="save" :loading="loading">
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_PROFILE } from '../../store/users/types';
import validators from '../../common/validators';
import languages from '../../enums/languages';
import EditProfileImageModal from './EditProfileImageModal';
import UserAvatar from '../shared/UserAvatar';
import GenerateProfileImageModal from './GenerateProfileImageModal';

export default {
  name: 'edit-user-profile-form',
  components: {
    UserAvatar
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      loading: false,
      isAvatarGenerationEnabled:
        process.env.VUE_APP_IS_AVATAR_GENERATION_ENABLED, // TODO: Remove this when we add S3
      languages: [
        {
          key: this.$i18n.t('languages.en'),
          value: languages.en
        },
        {
          key: this.$i18n.t('languages.bg'),
          value: languages.bg
        }
      ],
      user: {
        name: '',
        imageUrl: '',
        avatar: '',
        lang: languages.en,
        generatedAvatar: '',
        generatedAvatarUrl: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    this.user.name = this.currentUser.name;
    this.user.imageUrl = this.currentUser.imageUrl;
    this.user.avatar = this.currentUser.avatar;
    this.user.lang = this.currentUser.lang;
    this.user.generatedAvatar = this.currentUser.generatedAvatar;
    this.user.generatedAvatarUrl = this.currentUser.generatedAvatarUrl;
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang;
    },
    async changeProfileImage() {
      const result = await this.$root.$modal.show(
        EditProfileImageModal,
        {},
        {
          width: '800px'
        }
      );

      if (result) {
        this.user.avatar = { id: result.id };
        this.user.imageUrl = result.imagePath;
        this.user.generatedAvatar = null;
        this.user.generatedAvatarUrl = null;
      }
    },
    async openGenerateProfileImage() {
      const result = await this.$root.$modal.show(GenerateProfileImageModal);

      if (result) {
        this.user.generatedAvatar = result;
        this.user.generatedAvatarUrl = result.url;
      }
    },
    async save() {
      if (!this.$refs.editProfileForm.validate()) {
        return;
      }

      try {
        this.loading = true;

        await this.$store.dispatch(UPDATE_PROFILE, this.user);
        this.setLanguage(this.user.lang);

        this.$notify({
          type: 'success',
          text: this.$i18n.t('common.successfullySavedChanges')
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
