<template>
  <v-card tile>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('users.generateProfileImage') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6">
      <p class="text-center">
        <strong>
          <span>{{ $t('users.generateProfileImageCostText') }}</span>
          <v-chip color="app-blue" text-color="white" class="pl-2 gems-label">
            <gems-label
              :gems="generationCost"
              textColor="white---text"
            ></gems-label>
          </v-chip>
        </strong>
      </p>
      <v-form ref="generateProfileImageForm">
        <v-text-field
          solo
          v-model="generationData.prompt"
          :rules="[rules.required]"
          :label="$t('users.generateProfileImagePrompt')"
        ></v-text-field>
        <v-row class="text-right">
          <v-col class="pt-0">
            <v-btn color="app-blue" dark @click="generateProfileImage()">
              {{ $t('users.generateProfileImage') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-container v-if="generatedProfileImage" class="pa-0 mt-4 text-center">
        <v-row justify="center" align="center">
          <v-col md="4" cols="12">
            <v-avatar :size="150" class="bordered">
              <v-img :src="generatedProfileImage.url" contain></v-img>
            </v-avatar>
          </v-col>
          <v-col md="4" cols="12">
            <v-btn color="app-blue" dark @click="useAvatar()">
              {{ $t('avatars.useAvatar') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { GENERATE_AVATAR } from '../../store/users/types';
import GemsLabel from '../shared/GemsLabel';
import validators from '@/common/validators';
import avatarStoreModalStatus from '../../enums/avatar.store.modal.status';

export default {
  name: 'generate-profile-image-modal',
  components: {
    GemsLabel
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      loading: false,
      generatedProfileImage: null,
      generationCost: process.env.VUE_APP_OPENAI_CHATGPT_AVATAR_GENERATION_COST,
      generationData: {
        prompt: ''
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  methods: {
    async generateProfileImage() {
      const { gems } = this.currentUser.stats;

      if (!this.$refs.generateProfileImageForm.validate()) {
        return;
      }

      if (gems < this.generationCost) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('errors.notEnoughGemsToGenerateAvatar')
        });

        this.$emit('close', { status: avatarStoreModalStatus.buyGems });
        return;
      }

      try {
        this.loading = true;

        this.generatedProfileImage = await this.$store.dispatch(
          GENERATE_AVATAR,
          this.generationData
        );

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async useAvatar() {
      this.$emit('close', this.generatedProfileImage);
      // try {
      //   this.loading = true;

      //   await this.$store.dispatch(
      //     SET_GENERATED_AVATAR,
      //     this.generatedProfileImage
      //   );

      //   this.loading = false;
      //   this.$emit('close');
      // } catch (err) {
      //   this.loading = false;
      // }
    }
  }
};
</script>
